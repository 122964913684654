import qs, { IStringifyOptions } from 'qs';
import { API_PATHS, LOCAL_API_PORT, LOCAL_CLIENT_PORT, PATH_PARAMETER_SELECTORS } from 'src/utils/routing/routes';
import RouteBuilder, { PathParameters } from 'src/utils/routing/RouteBuilder';
import { throwIfInvalidRouteOrQueryParameter } from 'src/utils/parameterValidation';

interface QueryParameters {
    reportIds?: string | string[];
}

const QS_STRINGIFY_OPTIONS: IStringifyOptions = {
    addQueryPrefix: true,
    arrayFormat: 'comma'
};

class ApiRouteBuilder {
    private readonly context: RouteBuilder;
    private readonly urlBase: string;

    constructor({ context, urlBase }: { context: RouteBuilder, urlBase: string }) {
        this.context = context;
        this.urlBase = urlBase || this.getUrlBase();
    }

    login = (): string => {
        if (!this.context.location) {
            return '';
        }
        return this.getLoginUrlBase() + API_PATHS.LOGIN;
    };

    loginRedirect = (): string => {
        return this.withUrlBase(API_PATHS.LOGIN_REDIRECT);
    };

    logout = (): string => {
        return this.withUrlBase(API_PATHS.LOGOUT);
    };

    getUser = (): string => {
        return this.withUrlBase(API_PATHS.GET_USER);
    };

    getIsUserAuthenticated = (): string => {
        return this.withUrlBase(API_PATHS.GET_IS_USER_AUTHENTICATED);
    };

    getAllPartners = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_PARTNERS);
    };

    getMarketingContentPartners = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_MARKETING_CONTENT_PARTNERS);
    };
    getReportCategoriesForPartner = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return this.withUrlBase(
            API_PATHS.GET_REPORT_CATEGORIES_FOR_PARTNER
                .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
        );
    };

    getReports = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode(),
            reportCategoryCode = this.context.getReportCategoryCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, reportCategoryCode });

        return this.withUrlBase(
            API_PATHS.GET_REPORTS
                .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
                .replace(PATH_PARAMETER_SELECTORS.REPORT_CATEGORY_CODE, reportCategoryCode || '')
        );
    };

    viewReport = ({ reportId }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ reportId });

        return this.withUrlBase(
            API_PATHS.VIEW_REPORT
                .replace(PATH_PARAMETER_SELECTORS.REPORT_ID, reportId || '')
        );
    };

    downloadReports = ({ reportIds }: QueryParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ reportIds });

        return this.withUrlBase(
            API_PATHS.DOWNLOAD_REPORTS
            + qs.stringify({ reportId: reportIds }, QS_STRINGIFY_OPTIONS)
        );
    };

    deleteReport = ({ reportId }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ reportId });

        return this.withUrlBase(
            API_PATHS.DELETE_REPORT
                .replace(PATH_PARAMETER_SELECTORS.REPORT_ID, reportId || '')
        );
    };

    getAllPartnersAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_PARTNERS_ADMIN);
    };

    getPartnerAdmin = ( partnerCode: string ): string => {
        return this.withUrlBase(
            API_PATHS.GET_PARTNER_ADMIN
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
        );
    }

    getAllReportCategoriesAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_REPORT_CATEGORIES_ADMIN);
    };

    getAllFrequenciesAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_FREQUENCY_TYPES_ADMIN);
    };

    getAllUsersAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_USERS_ADMIN);
    };

    getTenants = (): string => {
            return this.withUrlBase(API_PATHS.GET_TENANTS);
        }

    getSaasUsers = ({ tenantId = this.context.getTenantId() }: PathParameters = {}): string => {
        return this.withUrlBase(API_PATHS.GET_SAAS_USERS).replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "");
    }

    getAllSaasUsers = ({ tenantId = this.context.getTenantId() }: PathParameters = {}): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_SAAS_USERS).replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "");
    }

    getAllSaasUserInvitations = ({ tenantId = this.context.getTenantId() }: PathParameters = {}): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_SAAS_USER_INVITATIONS).replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "");
    }

    getAllSaasUserRoles = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_SAAS_USER_ROLES);
    }

    updateCurrentSaasUser = (): string => {
        return this.withUrlBase(API_PATHS.UPDATE_CURRENT_SAAS_USER);
    }

    getThoughtSpotUser = ({ email }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ email });

        return this.withUrlBase(
            API_PATHS.GET_THOUGHTSPOT_USER
                .replace(PATH_PARAMETER_SELECTORS.EMAIL, email || '')
        );
    }

    getExternalUsersAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_EXTERNAL_USERS_ADMIN);
    };

    getAllFrequentlyAskedQuestionsAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_FREQUENTLY_ASKED_QUESTIONS_ADMIN);
    };

    createPartner = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_PARTNER);
    };

    createReportCategory = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_REPORT_CATEGORY);
    };

    createFrequencyType = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_FREQUENCY_TYPE);
    };

    createUser = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_USER);
    };

    inviteSaasUser = ({ tenantId = this.context.getTenantId(), isThoughtSpotUser }: PathParameters = {}): string => {
        return this.withUrlBase(API_PATHS.INVITE_SAAS_USER)
            .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "")
            .replace(PATH_PARAMETER_SELECTORS.IS_THOUGHTSPOT_USER, isThoughtSpotUser || "false")
    };


    createFrequentlyAskedQuestion(): string {
        return this.withUrlBase(API_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION);
    }

    editPartner = (): string => {
        return this.withUrlBase(API_PATHS.EDIT_PARTNER);
    };

    editReportCategory = (): string => {
        return this.withUrlBase(API_PATHS.EDIT_REPORT_CATEGORY);
    };

    editFrequencyType = (): string => {
        return this.withUrlBase(API_PATHS.EDIT_FREQUENCY_TYPE);
    };

    editUser(): string {
        return this.withUrlBase(API_PATHS.EDIT_USER);
    }

    editSaasUserRoles({ userId, isThoughtSpotUser, email, tenantId = this.context.getTenantId() }: PathParameters = {}): string {
        throwIfInvalidRouteOrQueryParameter({ userId });
        let emailEncoded = Buffer.from(email || "").toString('base64');

        return this.withUrlBase(
            API_PATHS.EDIT_SAAS_USER_ROLES
                .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "")
                .replace(PATH_PARAMETER_SELECTORS.USER_ID, userId || '')
                .replace(PATH_PARAMETER_SELECTORS.IS_THOUGHTSPOT_USER, isThoughtSpotUser || "false")
                .replace(PATH_PARAMETER_SELECTORS.EMAIL, emailEncoded)
        );
    }

    editFrequentlyAskedQuestion(): string {
        return this.withUrlBase(API_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION);
    }

    deletePartner = (
        {
            accessTypeCode,
            partnerCode
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return this.withUrlBase(
            API_PATHS.DELETE_PARTNER
                .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
        );
    };

    deleteReportCategory = ({ reportCategoryCode }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ reportCategoryCode });

        return this.withUrlBase(
            API_PATHS.DELETE_REPORT_CATEGORY
                .replace(PATH_PARAMETER_SELECTORS.REPORT_CATEGORY_CODE, reportCategoryCode || '')
        );
    };

    deleteFrequencyType = ({ frequencyTypeCode }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ frequencyTypeCode });

        return this.withUrlBase(
            API_PATHS.DELETE_FREQUENCY_TYPE
                .replace(PATH_PARAMETER_SELECTORS.FREQUENCY_TYPE_CODE, frequencyTypeCode || '')
        );
    };

    deleteSaasUser = ({ tenantId = this.context.getTenantId(), userId, email }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ userId });
        let emailEncoded = Buffer.from(email || "").toString('base64');

        return this.withUrlBase(
            API_PATHS.DELETE_SAAS_USER
                .replace(PATH_PARAMETER_SELECTORS.USER_ID, userId || '')
                .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "")
                .replace(PATH_PARAMETER_SELECTORS.EMAIL, emailEncoded || "")
        );
    };

    deleteSaasUserInvitation = ({ tenantId = this.context.getTenantId(), invitationId, email }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ invitationId });
        let emailEncoded = Buffer.from(email || "").toString('base64');

        return this.withUrlBase(
            API_PATHS.DELETE_SAAS_USER_INVITATION
                .replace(PATH_PARAMETER_SELECTORS.INVITATION_ID, invitationId || '')
                .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || "")
                .replace(PATH_PARAMETER_SELECTORS.EMAIL, emailEncoded || "")
        );
    };

    deleteUser = ({ userId }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ userId });

        return this.withUrlBase(
            API_PATHS.DELETE_USER
                .replace(PATH_PARAMETER_SELECTORS.USER_ID, userId || '')
        );
    };

    deleteFrequentlyAskedQuestion = ({ frequentlyAskedQuestionId }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ frequentlyAskedQuestionId });

        return this.withUrlBase(
            API_PATHS.DELETE_FREQUENTLY_ASKED_QUESTION
                .replace(PATH_PARAMETER_SELECTORS.FREQUENTLY_ASKED_QUESTION_ID, frequentlyAskedQuestionId || '')
        );
    };

    getFrequentlyAskedQuestions = (): string => {
        return this.withUrlBase(API_PATHS.GET_FREQUENTLY_ASKED_QUESTIONS);
    };

    getReportableIssues = (): string => {
        return this.withUrlBase(API_PATHS.GET_REPORTABLE_ISSUES);
    };

    submitReportAnIssueForm = (): string => {
        return this.withUrlBase(API_PATHS.SUBMIT_REPORT_AN_ISSUE_FORM);
    };

    validateReCaptcha = (): string => {
        return this.withUrlBase(API_PATHS.VALIDATE_RECAPTCHA);
    };

    getCustomerInvoices = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_CUSTOMER_INVOICES);
    };

    createCustomerInvoice = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_CUSTOMER_INVOICE);
    };

    editCustomerInvoice = (): string => {
        return this.withUrlBase(API_PATHS.EDIT_CUSTOMER_INVOICE);
    };

    deleteCustomerInvoice = ({ recordId }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ recordId });

        return this.withUrlBase(
            API_PATHS.DELETE_CUSTOMER_INVOICE
                .replace(PATH_PARAMETER_SELECTORS.FORM_RECORD_ID, recordId || '')
        );
    };

    getWalmartDisputes = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_WALMART_DISPUTES);
    };

    getWalmartHistoricDisputes = (): string => {
        return this.withUrlBase(API_PATHS.GET_HISTORIC_WALMART_DISPUTES);
    };

    createWalmartDispute = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_WALMART_DISPUTES);
    };

    editWalmartDispute = (): string => {
        return this.withUrlBase(API_PATHS.EDIT_WALMART_DISPUTES);
    };

    deleteWalmartDispute = ({ recordId }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ recordId });

        return this.withUrlBase(
            API_PATHS.DELETE_WALMART_DISPUTES
                .replace(PATH_PARAMETER_SELECTORS.FORM_RECORD_ID, recordId || '')
        );
    };

    getDashboardById = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode(),
            dashboardId = this.context.getDashboardId()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, dashboardId });

        return this.withUrlBase(
            API_PATHS.GET_DASHBOARD_BY_ID
                .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
                .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
        );
    };

    getDashboardsForPartner = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return this.withUrlBase(
            API_PATHS.GET_DASHBOARDS_FOR_PARTNER
                .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
        );
    };

    getAllDashboardsPartners = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_DASHBOARD_PARTNERS);
    };

    getAllDashboardsAdmin = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_DASHBOARDS_ADMIN);
    };

    createDashboard = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_DASHBOARD);
    };

    editDashboard = ({ dashboardId = this.context.getDashboardId() }: PathParameters = {}): string => {
        return this.withUrlBase(
            API_PATHS.EDIT_DASHBOARD
                .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
        );
    };

    deleteDashboard = ({ dashboardId = this.context.getDashboardId() }: PathParameters): string => {
        return this.withUrlBase(
            API_PATHS.DELETE_DASHBOARD
                .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
        );
    };

    trackDashboardView = ({ dashboardId = this.context.getDashboardId() }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ dashboardId });

        return this.withUrlBase(
            API_PATHS.TRACK_DASHBOARD_VIEW
                .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
        );
    };

    trackDashboardFail = ({ dashboardId = this.context.getDashboardId() }: PathParameters): string => {
            throwIfInvalidRouteOrQueryParameter({ dashboardId });

            return this.withUrlBase(
                API_PATHS.TRACK_DASHBOARD_FAIL
                    .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
            );
        };

    getQuicksightBoardInfo = ({ dashboardId = this.context.getDashboardId() }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ dashboardId });
        return this.withUrlBase(
            API_PATHS.GET_QUICKSIGHT_BOARD_INFO
            .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
        );
    };

    getLatestMarketingImage = (partnerCode: string): string => {
        return this.withUrlBase(
            API_PATHS.GET_LATEST_MARKETING_IMAGE
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || ''));
    }

    uploadMarketingImage = (partnerCode: string): string => {
        return this.withUrlBase(
            API_PATHS.UPLOAD_MARKETING_IMAGE
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || ''));
    }

    getLatestPartnerLogo = (partnerCode: string): string => {
        return this.withUrlBase(
            API_PATHS.GET_LATEST_PARTNER_LOGO
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || ''));
    }

    uploadPartnerLogo = (partnerCode: string): string => {
        return this.withUrlBase(
            API_PATHS.UPLOAD_PARTNER_LOGO
                .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || ''));
    }

    getCurrentBanner = (): string => {
        return this.withUrlBase(API_PATHS.GET_CURRENT_BANNER);
    }

    createBanner = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_BANNER);
    };

    editBanner = ({ bannerId = this.context.getBannerId() }: PathParameters = {}): string => {
        return this.withUrlBase(
            API_PATHS.EDIT_BANNER
                .replace(PATH_PARAMETER_SELECTORS.BANNER_ID, bannerId || '')
        );
    };

    getAllScheduledReportMonitors = (): string => {
        return this.withUrlBase(API_PATHS.GET_ALL_SCHEDULED_REPORT_MONITORS);
    };

    createScheduledReportMonitor = (): string => {
        return this.withUrlBase(API_PATHS.CREATE_SCHEDULED_REPORT_MONITOR);
    };

    updateScheduledReportMonitor = ({ reportMonitorId = this.context.getReportMonitorId() }: PathParameters = {}): string => {
        return this.withUrlBase(
            API_PATHS.UPDATE_SCHEDULED_REPORT_MONITOR
                .replace(PATH_PARAMETER_SELECTORS.REPORT_MONITOR_ID, reportMonitorId || '')
        );
    };

    deleteScheduledReportMonitor = ({ reportMonitorId = this.context.getReportMonitorId() }: PathParameters): string => {
        return this.withUrlBase(
            API_PATHS.DELETE_SCHEDULED_REPORT_MONITOR
                .replace(PATH_PARAMETER_SELECTORS.REPORT_MONITOR_ID, reportMonitorId || '')
        );
    };

    disableBanner = ({ bannerId = this.context.getBannerId() }: PathParameters = {}): string => {
        return this.withUrlBase(
            API_PATHS.DISABLE_BANNER
                .replace(PATH_PARAMETER_SELECTORS.BANNER_ID, bannerId || '')
        );
    };

    private withUrlBase = (route: string): string => {
        const urlBase = this.removeTrailingSlash(this.getUrlBase());

        return urlBase + route;
    };

    private removeTrailingSlash = (urlBase: string): string => {
        return urlBase.endsWith('/') ? urlBase.slice(0, -1) : urlBase;
    };

    private getUrlBase = (): string => {
        const { location } = this.context;

        return location ? location.origin : '';
    };

    private getLoginUrlBase = (): string => {
        const { location } = this.context;

        if (!location) {
            return '';
        }

        const { protocol, hostname } = location;

        let port = location.port ? ':' + location.port : '';
        if (port === `:${LOCAL_CLIENT_PORT}`) {
            port = `:${LOCAL_API_PORT}`;
        }
        return `${protocol}//${hostname}${port}`;
    };

    getThoughtspotAuthEntity = ({ pinboardId }: PathParameters = {}): string => {
        return this.withUrlBase(API_PATHS.THOUGHTSPOT_EMBEDDED_AUTH_ENTITY
            .replace(PATH_PARAMETER_SELECTORS.PINBOARD_ID, pinboardId || ''));
    };
}

export default ApiRouteBuilder;
