import ClientRouteBuilder from 'src/utils/routing/ClientRouteBuilder';
import ApiRouteBuilder from 'src/utils/routing/ApiRouteBuilder';
import { RouteComponentProps } from '@reach/router';
import { PATH_PARAMETER_SELECTORS, PathParameterSelector } from 'src/utils/routing/routes';

export interface RouteBuilderProps {
    location: RouterLocation;
    path: RouterPath;
    uri: RouterUri;
    apiUrlBase: string;
}

export type RouterLocation = RouteComponentProps['location'];
export type RouterPath = RouteComponentProps['path'];
export type RouterUri = RouteComponentProps['uri'];

export interface PathParameters {
    accessTypeCode?: string;
    partnerCode?: string;
    reportCategoryCode?: string;
    frequencyTypeCode?: string;
    userId?: string;
    frequentlyAskedQuestionId?: string;
    reportId?: string;
    recordId?: string;
    dashboardId?: string;
    dashboardName?: string;
    invitationId?: string;
    email?: string;
    tenantId?: string;
    pinboardId?: string;
    guid?: string;
    isThoughtSpotUser?: string;
    bannerId?: string;
    reportMonitorId?: string;
}

type PathParameter = keyof PathParameters;

class RouteBuilder {
    readonly api: ApiRouteBuilder;
    readonly client: ClientRouteBuilder;

    readonly location: RouterLocation;
    readonly path: RouterPath;
    readonly uri: RouterUri;
    readonly pathParts: string[];
    readonly uriParts: string[];
    readonly pathParameters: PathParameters;

    constructor(
        {
            location = undefined,
            path = undefined,
            uri = undefined,
            apiUrlBase = ''
        }: Partial<RouteBuilderProps> = {}
    ) {
        this.location = location;
        this.path = path;
        this.uri = uri;
        this.pathParts = path ? path.split('/') : [];
        this.uriParts = uri ? uri.split('/') : [];
        this.pathParameters = this.determinePathParameters(this.pathParts, this.uriParts);

        this.client = new ClientRouteBuilder({ context: this });
        this.api = new ApiRouteBuilder({ context: this, urlBase: apiUrlBase });
    }

    getAccessTypeCode(): string | undefined {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE);
    }

    getTenantId(): string | undefined {
            return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.TENANT_ID);
        }

    getPartnerCode = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.PARTNER_CODE);
    };

    getPinboardId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.PINBOARD_ID);
    };

    getReportCategoryCode = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.REPORT_CATEGORY_CODE);
    };

    getFrequencyTypeCode = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.FREQUENCY_TYPE_CODE);
    };

    getUserId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.USER_ID);
    };

    getFrequentlyAskedQuestionId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.FREQUENTLY_ASKED_QUESTION_ID);
    };

    getFormRecordId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.FORM_RECORD_ID);
    };

    getReportId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.REPORT_ID);
    };

    getDashboardId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.DASHBOARD_ID);
    };

    getBannerId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.BANNER_ID);
    };

    getReportMonitorId = (): string | undefined => {
        return this.getPathParameterFromUri(PATH_PARAMETER_SELECTORS.REPORT_MONITOR_ID);
    };

    getUriFromPath = ({ path }: { path: string }): string | undefined => {
        try {
            return path.split('/')
                .map(x => {
                    if (x.startsWith(':')) {
                        const pathParameterFromUri = this.getPathParameterFromUri(x as PathParameterSelector);

                        if (!pathParameterFromUri) {
                            throw new Error(`Path Parameter (${x}) not found in uri (${this.uri})`);
                        }

                        return pathParameterFromUri;
                    }

                    return x;
                })
                .join('/');
        } catch (e) {
            console.error(e);
        }
    };

    private determinePathParameters = (pathParts: string[], uriParts: string[]): PathParameters => {
        return pathParts.reduce(
            (pathParameters: PathParameters, pathPart: string, i: number) => {
                if (!pathPart.startsWith(':')) {
                    return pathParameters;
                }

                pathParameters[this.convertSelectorToKey(pathPart)] = uriParts[i];

                return pathParameters;
            },
            {}
        );
    };

    private getPathParameterFromUri = (pathParameterSelector: PathParameterSelector): string | undefined => {
        return this.pathParameters[this.convertSelectorToKey(pathParameterSelector)];
    };

    private convertSelectorToKey = (pathParameterSelector: string): PathParameter => {
        return pathParameterSelector.substring(1) as PathParameter;
    };
}

export default RouteBuilder;
