import {Button, Spinner} from '@c1/gravity-react';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import CloseButton from 'src/components/buttons/CloseButton/CloseButton';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetDashboardResponse} from "../../types/apiResponse";
import { Environment } from 'src/types/environment';
import axios, {AxiosError} from 'axios';
import { navigate, useLocation, useParams } from '@reach/router';
import { useEnvironment } from 'src/contextProviders/EnvironmentProvider/EnvironmentContext';
import InformationModal from 'src/components/modals/InformationModal/InformationModal';
import { isMobile } from 'react-device-detect';
import iframeResolutionHelper from 'src/utils/iframeResolutionHelper';
import {
    AuthenticationContextState,
    useAuthenticationContext
} from "../../contextProviders/AuthenticationProvider/AuthenticationContext";
import {getFromApi} from "../../utils/getFromApi";
import "./RStudioLiveBoard.css"
import {Simulate} from "react-dom/test-utils";
import compositionStart = Simulate.compositionStart;
import error = Simulate.error;


function RStudioLiveBoard(): FunctionComponentReturnType {

    const [dashboard, setDashboard] = useState<GetDashboardResponse | null>(null)
    const [axiosError, setAxiosError] = useState<AxiosError | null>(null);
    const [errorOnLoad, setErrorOnLoad] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<String>('Something went wrong. Please contact the support team');
    const [dashboardUrl, setDashboardUrl] = useState<string>(null)
    const { dashboardId } = useParams()
    const [loading, setLoading] = useState<boolean>(true);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const apiRoute = routeBuilder.api.getDashboardById();
    const location = useLocation()
    const apiRouteFail: string = routeBuilder.api.trackDashboardFail({ dashboardId: dashboardId });

    let titleObject: any = !!location.state && typeof (location.state) === "object" ? location.state : { title: "RStudio View" };
    useEffect(() => {
        if (document.getElementById("page-template-title-element") && document.getElementById("page-template-title-element")!.textContent)
            document.getElementById("page-template-title-element")!.textContent = titleObject.title;
    }, []);

    useEffect(() => {
         iframeResolutionHelper(null, (iframeDoc, status) =>{
             let shouldSendFail = false;
             console.log("Status: " + status)

             if (status === 400 || status === 404 || status === 500 || status === 503) {
                 setErrorOnLoad(true);
                 setErrorMessage('Sorry - we are currently unable to process your request.  Please try again in a few minutes');
                 setDashboard(null);
             }

            if(status == null && Math.floor(status/100) != 2) {
                shouldSendFail = true;
                console.log("Dashboard '" + dashboardId + "' failed because status=" + status);
            }
            if(iframeDoc) {
                try {
                    var element = iframeDoc.getElementsByTagName("app-controller")
                    shouldSendFail = !element;
                } catch (e) {
                    console.log("Dashboard '" + dashboardId + "' failed because error=" + e)
                    shouldSendFail = true;
                }
            } else {
                shouldSendFail = true;
                console.log("Dashboard '" + dashboardId + "' failed because iframe was not accessible")
            }
            if (shouldSendFail) {
                console.log("Dashboard '" + dashboardId + "' failed to load, sending error report")
                axios.post(apiRouteFail);
            } else {
                console.log("Dashboard '" + dashboardId + "' looks to have loaded successfully")
            }
         })
    }, [])

    useEffect(() => {
        if (dashboard) {
            setDashboardUrl(sanitizeURL(dashboard.dashboardUrl));

            const iframe = document.getElementById('rstudio-embed')
            iframe.style.height = window.innerHeight + 'px'
        }
    }, [dashboard]);

    useEffect(getFromApi(apiRoute, setDashboard, setAxiosError), [apiRoute]);

    function sanitizeURL(url: string): string {
        const validUrlPattern = /\/content\/[a-zA-Z0-9-]+/;

        const match = validUrlPattern.test(url);
        const encoded = encodeURI(url)
        return match ? encodeURI(url) : '';
    }

    function adjustIFrameHeight() {
        if (dashboard) {
            const iframe = document.getElementById('rstudio-embed')
            iframe.style.height = window.innerHeight + 'px'
        }
    }

    window.addEventListener('load', adjustIFrameHeight);
    window.addEventListener('resize', adjustIFrameHeight)

    const loader =
        <div className="rstudio-loader-container">
            <div className="rstudio-loader"></div>
        </div>

    return (
        <div>
            <Button id={'copp-button-return'} className="grv-margin--tiny" type="regressive"
                    onClick={() => navigate(routeBuilder.client.toDashboardSelectionPage())} compact>Return</Button>
            <br/>
            <div>
                <main>
                    <div className="rstudio-liveboard-container-div">
                        {loading && <div>{loader}</div>}
                        {dashboard
                            ?
                            <iframe src={decodeURI(dashboardUrl)} id="rstudio-embed" onLoad={() => setLoading(false)}></iframe>
                            :
                            errorOnLoad && <p>{errorMessage}</p>
                        }
                    </div>
                </main>
            </div>
        </div>
    );
}

export default RStudioLiveBoard;
