import React, { useState, FormEvent } from 'react';
import axios, { AxiosResponse } from 'axios';
import Form from 'src/components/formElements/Form/Form';
import RadioButtonGroup from 'src/components/formElements/RadioButtonGroup/RadioButtonGroup';
import TextInput from 'src/components/formElements/TextInput/TextInput';
import { Partner } from 'src/types/partner';
import { FunctionComponentProps, FunctionComponentReturnType } from 'src/types/sharedReact';
import { AccessType } from 'src/types/accessType';
import { ReportCategory } from 'src/types/reportCategory';
import { FrequencyType } from 'src/types/frequencyType';
import AddReportCategoryDropdownSelect
    from 'src/components/AddReportCategoryDropdownSelect/AddReportCategoryDropdownSelect';
import { convertNameAndCodeToLabelAndValue } from 'src/utils/typeConversions';
import AddReportCategoryCheckboxList from 'src/components/AddReportCategoryCheckboxList/AddReportCategoryCheckboxList';
import { navigate } from '@reach/router';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import FormActionTray from 'src/components/formElements/FormActionTray/FormActionTray';
import Tile from 'src/containers/Tile/Tile';
import { isDuplicatePartner } from 'src/utils/duplicationValidation';
import DuplicatePartnerError from 'src/components/errors/DuplicatePartnerError';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import _ from 'lodash';
import UploadLogo from '../formElements/UploadLogo/UploadLogo';
import { GrvTypographyClass } from 'src/types/gravity';
import { ScheduledReportMonitorDTO } from 'src/types/scheduledReportMonitor';

interface PartnerFormProps extends FunctionComponentProps {
    accessTypes: AccessType[];
    frequencyTypes: FrequencyType[];
    partners: Partner[];
    reportCategories: ReportCategory[];
    partner?: Partner;
    monitors?: ScheduledReportMonitorDTO[];
    removeReportMonitorState?: (_reportMonitorId: string) => void,
    updateReportMonitorState?: (_reportMonitor: ScheduledReportMonitorDTO) => void
}

function PartnerForm(
    {
        accessTypes,
        frequencyTypes,
        partners,
        reportCategories,
        partner,
        monitors,
        removeReportMonitorState,
        updateReportMonitorState
    }: PartnerFormProps
): FunctionComponentReturnType {
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const [accessTypeCode, setAccessTypeCode] = useState<string | null>(partner ? partner.type : null);
    const [partnerCode, setPartnerCode] = useState<string | null>(partner ? partner.code : null);
    const [partnerName, setPartnerName] = useState<string | null>(partner ? partner.name : null);
    const [selectedReportCategories, setSelectedReportCategories] =
        useState<ReportCategory[] | null>(partner ? partner.categories : null);
    const [monitorsToCreate, setMonitorsToCreate] = useState<ScheduledReportMonitorDTO[]>([]);
    const [monitorsToUpdate, setMonitorsToUpdate] = useState<ScheduledReportMonitorDTO[]>([]);
    const [monitorsToDelete, setMonitorsToDelete] = useState<ScheduledReportMonitorDTO[]>([]);

    function addMonitorToCreate(monitor: ScheduledReportMonitorDTO) {
        const updatedMonitorsToCreate = [...monitorsToCreate];
        updatedMonitorsToCreate.push(monitor);
        setMonitorsToCreate(updatedMonitorsToCreate);
        updateReportMonitorState(monitor);
    }

    function addMonitorToUpdate(monitor: ScheduledReportMonitorDTO) {
        const updatedMonitorsToUpdate = [...monitorsToUpdate];
        updatedMonitorsToUpdate.push(monitor);
        setMonitorsToUpdate(updatedMonitorsToUpdate);
        updateReportMonitorState(monitor);
    }

    function addMonitorToDelete(monitor: ScheduledReportMonitorDTO) {
        const updatedMonitorsToDelete = [...monitorsToDelete];
        updatedMonitorsToDelete.push(monitor);
        setMonitorsToDelete(updatedMonitorsToDelete);
        removeReportMonitorState(monitor.reportMonitorId);
    }

    const submitButtonText = partner ? 'Save Changes' : 'Add Partner';

    const isDuplicate: boolean = partner ? false : isDuplicatePartner(accessTypeCode, partnerCode, partners);

    async function handleSubmit(event: FormEvent<HTMLFormElement>): Promise<void> {
        const trimmedAccessTypeCode = accessTypeCode && accessTypeCode.trim();
        const trimmedPartnerCode = partnerCode && partnerCode.trim();
        const trimmedPartnerName = partnerName && partnerName.trim();
        const nullSafeSelectedReportCategories = selectedReportCategories || [];

        if (
            isDuplicate ||
            !trimmedAccessTypeCode ||
            !trimmedPartnerCode ||
            !trimmedPartnerName ||
            nullSafeSelectedReportCategories.find(x => !x.frequencyTypes.length)
        ) {
            setAccessTypeCode(trimmedAccessTypeCode || '');
            setPartnerCode(trimmedPartnerCode || '');
            setPartnerName(trimmedPartnerName || '');
            setSelectedReportCategories(nullSafeSelectedReportCategories);
            return;
        }

        const newPartner: Partner = {
            name: trimmedPartnerName,
            code: trimmedPartnerCode,
            type: trimmedAccessTypeCode,
            categories: nullSafeSelectedReportCategories
        };

        let selectedReportCategoryCodes = nullSafeSelectedReportCategories.map(x => x.code);
        let deletedCategoryMonitors = monitors?.filter(monitor => !selectedReportCategoryCodes.includes(monitor.categoryCode));

        const upsert: Promise<AxiosResponse> = partner ?
            axios.put(routeBuilder.api.editPartner(), newPartner) :
            axios.post(routeBuilder.api.createPartner(), newPartner);

        for (let monitor of monitorsToCreate) {
            await axios.post(routeBuilder.api.createScheduledReportMonitor(), monitor);
        }

        for (let monitor of monitorsToUpdate) {
            await axios.put(routeBuilder.api.updateScheduledReportMonitor({ reportMonitorId: monitor.reportMonitorId }), monitor);
        }

        for (let monitor of [...monitorsToDelete, ...deletedCategoryMonitors]) {
            await axios.delete(routeBuilder.api.deleteScheduledReportMonitor(monitor));
        }

        setMonitorsToCreate([]);
        setMonitorsToUpdate([]);
        setMonitorsToDelete([]);

        await upsert
            .then(() => navigate(routeBuilder.client.toManagePartnersPage()))
            .catch(console.error);
    }

    return (
        <Tile>
            <Form onSubmit={handleSubmit} name={_.camelCase(submitButtonText)}>
                <RadioButtonGroup
                    label="Access Type"
                    options={accessTypes.map(convertNameAndCodeToLabelAndValue)}
                    value={accessTypeCode}
                    onChange={setAccessTypeCode}
                    errors={[isDuplicate && <DuplicatePartnerError key="duplicate-partner-error"/>]}
                    disabled={Boolean(partner)}
                    required
                />
                <TextInput
                    label="Partner Code"
                    value={partnerCode}
                    onChange={setPartnerCode}
                    errors={[isDuplicate && <DuplicatePartnerError key="duplicate-partner-error"/>]}
                    disabled={Boolean(partner)}
                    required
                />
                <TextInput
                    label="Partner Name"
                    value={partnerName}
                    onChange={setPartnerName}
                    required
                />
                <p className={GrvTypographyClass.bodySmall}>Partner Logo</p>
                <UploadLogo
                    disabled={partnerCode === null || partnerCode === ''}
                    partnerCode={partnerCode}
                    partner={partner}
                />
                <AddReportCategoryDropdownSelect
                    reportCategories={reportCategories}
                    selectedReportCategories={selectedReportCategories}
                    onChange={setSelectedReportCategories}
                />
                {
                    selectedReportCategories &&
                    <AddReportCategoryCheckboxList
                        frequencyTypes={frequencyTypes}
                        selectedReportCategories={selectedReportCategories}
                        monitors={monitors}
                        addMonitorToCreate={addMonitorToCreate}
                        addMonitorToUpdate={addMonitorToUpdate}
                        addMonitorToDelete={addMonitorToDelete}
                        onChange={setSelectedReportCategories}
                        isExternal={accessTypeCode == 'EXT'}
                    />
                }
                <FormActionTray
                    cancelButtonLinkTo={routeBuilder.client.toManagePartnersPage()}
                    submitButtonText={submitButtonText}
                />
            </Form>
        </Tile>
    );
}

export default PartnerForm;
